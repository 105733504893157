import "./Card.css";
export default function Card({ project }) {
  return (
    <div className="card">
      <img src={project.img} alt="Crad" />
      <div className="card-content">
        <div className="card-title">{project.title}</div>
        <div className="card-details">
          {project.details}
        </div>
      </div>
    </div>
  );
}
