import React, { useState } from "react";

const eduData = [
  {
    main: "Full Stack Developer",
    percent: "",
    year: 2023,
    collage: "Coding Ninjas",
    img: "https://yt3.googleusercontent.com/Qak2Unp_tDxiEMmSwOj8oSUZLMytk7fcWSZA5UL2eWaxBOgUCnyvEQorbcwnVQderfZQLrko=s900-c-k-c0x00ffffff-no-rj",
  },
  {
    main: "B.Tech (ME)",
    percent: "",
    year: 2020,
    collage: "Naraina College of Engineering and Technology",
    img: "https://onlinetranscripts.org/wp-content/uploads/2020/02/Dr-A-P-J-Apdul-Kalam-Technical-Univeristy-UPTU.png",
  },
  {
    main: "Intermediate (UP Board)",
    percent: "74%",
    year: 2016,
    collage: "Kunwar Singh Inter College",
    img: "https://seeklogo.com/images/S/seal-of-uttar-pradesh-logo-130FCF327B-seeklogo.com.png",
  },
  {
    main: "High School (UP Board)",
    percent: "83%",
    year: 2014,
    collage: "MVVM Ballia",
    img: "https://seeklogo.com/images/S/seal-of-uttar-pradesh-logo-130FCF327B-seeklogo.com.png",
  },
];
const certificateData = [
  {
    title: "Certification 1",
    organization: "Certification Organization 1",
    img: "https://example.com/certification-image-1.png",
  },
  {
    title: "Certification 2",
    organization: "Certification Organization 2",
    img: "https://example.com/certification-image-2.png",
  },
];
export default function Education() {
  const [certificateVisible, setCertificateVisible] = useState(false);
  return (
    <div className="edu">
      <div className="edu-display">
        {eduData.map((edu, index) => (
          <div className="edu-container" key={index}>
            <div className="edu-top">
              <img src={edu.img} alt="edu" />
              <span>
                <h3>{edu.percent}</h3>
                <div className="hr"></div>
                <h4>Year {edu.year}</h4>
              </span>
            </div>
            <div className="edu-botom">
              <h3>
                <small>{edu.main}</small>
                <br />
                {edu.collage}
              </h3>
            </div>
          </div>
        ))}
      </div>
      <div className="certificate">
        <button onClick={() => setCertificateVisible(!certificateVisible)}>
          {certificateVisible ? "Hide Certificates" : "Show Certificates"}
        </button>

        {certificateVisible && (
          <div className="certificate-details">
            {certificateData.map((certificate, index) => (
              <div className="certificate-container" key={index}>
                <img src={certificate.img} alt={certificate.title} />
                <h3>{certificate.title}</h3>
                <p>{certificate.organization}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
