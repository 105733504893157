import imguc from "../../images/404-construction.gif";

export default function Projects() {
  return (
    <div className="Project">
      <h3>Project</h3>
      <div className="img-u-c">
        <img src={imguc} alt="under-construction" className="imguc" />
      </div>
    </div>
  );
}
