import profile from "../../images/profile.jpg";
import "./home.css";
import { projectsdata } from "../Data/Data";
import { useEffect, useState } from "react";
import Card from "../Card/Card";
import ListCard from "../Card/ListCard";

export default function Home() {
  const [projects, setProject] = useState([]);

  useEffect(() => {
    setProject(projectsdata);
  }, []);
  const handleDownload = () => {
    const pdfFilePath = process.env.PUBLIC_URL + "/pdf/cv.pdf";
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = "cv.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="Home">
      <div className="profile-container">
        <div className="img-display">
          <img alt="profile" src={profile} />
        </div>
        <div className="ditails-container">
          <div>
            <h3 className="name">Anubhav Kumar</h3>
            <p>@anubhavcodes</p>
          </div>
          <div className="btnn">
            <a
              href="https://github.com/avisinghss"
              target="_blank"
              rel="noreferrer"
            >
              <button>
                Follow me on <i className="fa-brands fa-github"></i>
              </button>
            </a>
            <br />
            <button onClick={handleDownload}>
              Download CV <i className="fa-solid fa-download"></i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <h2>Projects</h2>
        <div className="card-container">
          {projects.map((project) => (
            <Card key={project.id} project={project} />
          ))}
        </div>
        <h2>What I know</h2>
        <div className="wht-i-knw">
          <ListCard />
        </div>
        <h2>Other</h2>
        <div className="profile-container exp">
          <h3>#Todo add</h3>
        </div>
      </div>
    </div>
  );
}
