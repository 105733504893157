export const projectsdata = [
  {
    id: 0,
    title: "Codeial- Social App",
    details: "NodeJS Express EJS MongoDb",
    img: "https://live.staticflickr.com/65535/53399741899_134e519700_z.jpg",
  },
  {
    id: 1,
    title: "Todo-List",
    details: "ReactJS ApiFetch",
    img: "https://live.staticflickr.com/65535/53399610053_2858aeb54b_z.jpg",
  },
  {
    id: 2,
    title: "E-Commerce Api",
    details: "NodeJS Express",
    img: "https://live.staticflickr.com/65535/53399853695_34c0a777d9_z.jpg",
  },
  {
    id: 3,
    title: "Netflix Clone",
    details: "HTML CSS JS",
    img: "https://live.staticflickr.com/65535/53399610058_81fc4c51ed_z.jpg",
  },
];
