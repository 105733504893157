import React from "react";
import "./Social.css";

export default function Professional() {
  const Social = [
    {
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/anubhavsingh-codes/",
      img: "https://live.staticflickr.com/65535/53401212649_4488204c44_z.jpg",
    },
    {
      title: "Github",
      link: "https://github.com/avisinghss",
      img: "https://live.staticflickr.com/65535/53399957902_c577b44c31_z.jpg",
    },
    {
      title: "Leetcode",
      link: "https://leetcode.com/anubhavsingh12/",
      img: "https://live.staticflickr.com/65535/53401212664_5dd3c48003_z.jpg",
    },
    {
      title: "Hackerrank",
      link: "https://www.hackerrank.com/profile/anubhavsinghrr",
      img: "https://live.staticflickr.com/65535/53399957907_86d7b10673_z.jpg",
    },
  ];

  return (
    <div className="Social">
      {Social.map((item, index) => (
        <div className="social-box" key={index}>
          <div className="scl-img">
            <img src={item.img} alt={item.title} />
          </div>
          <div className="scl-title">
            <h4>
              {item.title}{" "}
              <a href={item.link} target="_blank" rel="noreferrer">
                <i class="fa-solid fa-link linko"></i>
              </a>
            </h4>
          </div>
        </div>
      ))}
    </div>
  );
}
