import React from "react";
import "./Social.css";

export default function Social() {
  const Social = [
    {
      title: "Twitter",
      link: "https://twitter.com/avisinghss",
      img: "https://live.staticflickr.com/65535/53401170874_bac1e57ee2_z.jpg",
    },
    {
      title: "Threads",
      link: "https://www.threads.net/@anubhavcodes",
      img: "https://live.staticflickr.com/65535/53399915347_7c9e62fbd7_z.jpg",
    },
    {
      title: "Facebook",
      link: "https://www.facebook.com/avisnghss1/",
      img: "https://live.staticflickr.com/65535/53401033968_870cd1b17c_z.jpg",
    },
    {
      title: "Instagram",
      link: "https://www.instagram.com/avisinghss/",
      img: "https://live.staticflickr.com/65535/53401033973_1d998df3eb_z.jpg",
    },
  ];

  return (
    <div className="Social">
      {Social.map((item, index) => (
        <div className="social-box" key={index}>
          <div className="scl-img">
            <img src={item.img} alt={item.title} />
          </div>
          <div className="scl-title">
            <h4>
              {item.title}{" "}
              <a href={item.link} target="_blank" rel="noreferrer">
                <i class="fa-solid fa-link linko"></i>
              </a>
            </h4>
          </div>
        </div>
      ))}
    </div>
  );
}
