import "./Skills.css";

const skillsData = [
  { name: "Java", percent: 70, color: "mb-blue" },
  { name: "JavaScript", percent: 85, color: "mb-teal" },
  { name: "HTML", percent: 90, color: "mb-green" },
  { name: "CSS", percent: 90, color: "mb-yellow" },
  { name: "React", percent: 80, color: "mb-sky-blue" },
  { name: "Node Js", percent: 65, color: "mb-red" },
  { name: "Express Js", percent: 70, color: "mb-teal" },
  { name: "Git", percent: 70, color: "mb-dark" },
  { name: "MongoDb", percent: 75, color: "mb-blue" },
  { name: "Fire Base", percent: 65, color: "mb-orange" },
];

export default function SkillProgress() {
  return (
    <div className="skills-display">
      {skillsData.map((skill, index) => (
        <div key={index} className="skill-progress">
          <div className={`percent-${skill.percent} ${skill.color}`}>
            <div className="skill-name">
              <span>{skill.name}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
