import { React, useState } from "react";
import Home from "./components/Home/Home";
import Contact from "./components/Contacts/Contact";
import Skills from "./components/Skills/Skills";
import Project from "./components/Projects/Project";
import logo from "./images/logo.png";
import mygif from "./images/mygif.gif";
import "./App.css";

function App() {
  const [currentPage, setCurrentPage] = useState("home");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = (page) => {
    setCurrentPage(page);
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div className={`App ${menuOpen ? "menu-open" : ""}`}>
      <aside>
        <ul>
          <li onClick={() => handleMenuClick("home")}>
            <i className="fa-solid icon fa-house"></i> Home
          </li>
          <li onClick={() => handleMenuClick("skills")}>
            <i className="fa-solid icon fa-bolt"></i>Skills
          </li>
          <li onClick={() => handleMenuClick("project")}>
            <i className="fa-solid icon fa-diagram-project"></i>Project
          </li>
          <li onClick={() => handleMenuClick("contact")}>
            <i className="fa-solid icon fa-link"></i>Contact
          </li>
          <img src={mygif} id="mygif" alt="mygif" />
        </ul>
      </aside>
      <main>
        <div className="menu-toggle" onClick={toggleMenu}>
          {menuOpen ? (
            <i class="fa-solid fa-xmark"></i>
          ) : (
            <i class="fa-solid fa-ellipsis-vertical"></i>
          )}
        </div>
        <div className="heading-top">
          <img id="logo" alt="logo" src={logo} />
          <h2>Anubhav Codes</h2>
        </div>
        {currentPage === "home" && <Home />}
        {currentPage === "skills" && <Skills />}
        {currentPage === "project" && <Project />}
        {currentPage === "contact" && <Contact />}
      </main>
    </div>
  );
}

export default App;
