import React, { useState } from "react";
import "./Contact.css";
import Professional from "./Professonal";
import Social from "./Social";
import Personal from "./Personal";

export default function Contact() {
  const accordionItems = [
    { title: "Professional", content: <Professional /> },
    { title: "Social", content: <Social /> },
    {
      title: "Personal",
      content: <Personal />,
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="Contact">
      <h3>Contact</h3>
      {accordionItems.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(index)}
          >
            <span className={`arrow ${openIndex === index ? "up" : "down"}`}>
              <i className="fa-solid  fa-caret-down"></i>
            </span>
            {item.title}
          </div>
          {openIndex === index && (
            <div className="accordion-content">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
}
