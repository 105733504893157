import "./CardList.css";
export default function ListCard() {
  return (
    <div className="card-list">
      <div className="card-list-content">
        <div className="card-list-title">Frontend</div>
        <div className="card-list-details">
          <ul>
            <li>HTML</li>
            <li>CSS</li>
            <li>JavaScript</li>
            <li>Tailwind</li>
            <li>SASS</li>
            <li>REACT JS</li>
          </ul>
        </div>
      </div>
      <div className="card-list-content">
        <div className="card-list-title">Backend</div>
        <div className="card-list-details">
          <ul>
            <li>NodeJs</li>
            <li>Express Js</li>
            <li>Java</li>
            <li>Data Structure</li>
            <li>Algo</li>
          </ul>
        </div>
      </div>
      <div className="card-list-content">
        <div className="card-list-title">Database</div>
        <div className="card-list-details">
          <ul>
            <li>MongoDb</li>
            <li>MySql</li>
            <li>Firebase</li>
          </ul>
        </div>
      </div>
      <div className="card-list-content">
        <div className="card-list-title">Other</div>
        <div className="card-list-details">
          <ul>
            <li>Git</li>
            <li>Canva</li>
            <li>AWS</li>
            <li>Figma</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
