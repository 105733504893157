import { useState } from "react";
import "./Skills.css";
import SkillProgress from "./SkillProgress";
import Education from "./Education";

export default function Skills() {
  const [currentPage, setCurrentPage] = useState(1);

  const handleToggle = (page) => {
    setCurrentPage(page);
  };
  return (
    <div className="Skill">
      <h3>Skills</h3>
      <div className="toggleContainer">
        <button
          className={currentPage === 1 ? "activeButton" : "button"}
          onClick={() => handleToggle(1)}
        >
          Skills
        </button>
        <button
          className={currentPage === 2 ? "activeButton" : "button"}
          onClick={() => handleToggle(2)}
        >
          Education
        </button>
      </div>

      <div className="pageContent">
        {currentPage === 1 && <SkillProgress />}
        {currentPage === 2 && <Education />}
      </div>
    </div>
  );
}
