import React, { useEffect, useState } from "react";

const Personal = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ ...styles.cardd, ...(isMobile && styles.mobile) }} id="prsnl">
      <h2>Anubhav Kumar</h2>
      <p>
        <i className="fa-solid fa-phone"></i> : +91-9129856763
      </p>
      <p>
        <i className="fa-solid fa-envelope"></i> : anuhavsinghrr@gmail.com
      </p>
      <p>
        {" "}
        <i className="fa-solid fa-location-dot"></i> : Near Ashoka Palace Harpur,
        Ballia
      </p>
    </div>
  );
};

const styles = {
  cardd: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px",
    width: "300px",
    margin: "16px",
    backgroundColor: "#093637",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  mobile: {
    width: "100%",
    padding: "10px",
    margin: "0",
    fontSize: "14px",
  },
};

export default Personal;
